import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { urls } from "~/api/config";

export const SLOT_PLACEHOLDER_IMAGE_SRC =
  "/images/product/product-slot-placeholder.webp";
export const SLOT_PLACEHOLDER_URL =
  "https://my.magnit.ru/?utm_source=cosmetic.magnit.ru&utm_campaign=SKU&utm_medium=free";

interface IContentPromoCatalogCategory {
  disclaimer: string;
  imageURL: string;
  isActive: true;
  labelText: string;
  labelType: number; // 1 - nope, 2 - sale, 3 - text
  title: string;
  titleType: number; // 1- black, 2 - white
  offerURL: string;
  position: number;
  promoEndDate: string;
  promoStartDate: string;
  publicImageURL: string;
  showEndDate: string;
  showStartDate: string;
}

interface IContentPromoCatalog {
  items: IContentPromoCatalogCategory[];
}

interface IContentStatus {
  catalog: "initial" | "pending" | "success" | "error";
  newArrival: "initial" | "pending" | "success" | "error";
}

export enum ProductCatalogType {
  Promo = 1,
  Main = 2,
}

export interface ICategorySectionResponse {
  adfoxPayload?: never;
  categoryId?: number;
  categoryType: ProductCatalogType;
  disclaimer?: string;
  title: string;
}

export const useContentStore = defineStore("content", () => {
  const catalog = ref<IContentPromoCatalog>();
  const newArrival = ref<ICategorySectionResponse>();

  const status = reactive<IContentStatus>({
    catalog: "initial",
    newArrival: "initial",
  });

  async function requestCatalog() {
    status.catalog = "pending";

    const { data, error } = await useTransport<IContentPromoCatalog>(
      urls.content.catalogPromosDiscounts,
      {
        method: "GET",
      },
    );

    if (data.value) {
      catalog.value = data.value;
      status.catalog = "success";
    }

    if (error.value) {
      status.catalog = "error";
    }
  }

  async function requestNewArrival() {
    status.newArrival = "pending";

    const { data, error } = await useTransport<ICategorySectionResponse>(
      urls.content.newArrival,
      {
        method: "GET",
      },
    );

    if (data.value) {
      newArrival.value = data.value;
      status.newArrival = "success";
    }

    if (error.value) {
      status.newArrival = "error";

      throw createError({
        statusCode: error.value.statusCode,
        message: error.value.data?.message || "Error newArrival request",
        fatal: true,
      });
    }
  }

  return {
    requestCatalog,
    catalog,
    requestNewArrival,
    newArrival,
    status,
  };
});
